.MuiToggleButtonGroup-root {
  border: 1px solid white !important;
  height: 80%;
  margin: auto 0;
}

.MuiToggleButton-root.Mui-selected {
  background-color: white !important;
}

.MuiToggleButton-root {
  padding: 0 0.75rem !important;
}
