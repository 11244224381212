.MuiDataGrid-root {
  border: none !important;
}

.MuiDataGrid-columnsContainer {
  padding-left: 8px;
  border-bottom: 1px solid #576079 !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
  overflow: initial !important;
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #ffffff;
}

.MuiDataGrid-root .MuiDataGrid-dataContainer {
  direction: ltr !important;
}

.MuiDataGrid-root .MuiDataGrid-viewport {
  margin-left: 6px !important;
}

.MuiDataGrid-root .MuiDataGrid-row {
  background-color: #151724;
  border-radius: 8px !important;
  margin-bottom: 0.33rem !important;
}

.MuiDataGrid-root .MuiDataGrid-cell {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  color: #ffffff;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.MuiSelect-outlined.MuiSelect-outlined {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.25rem;
  text-decoration-line: underline;
  color: #ffffff;
}

.MuiSelect-select {
  padding: 0 32px 0 0 !important;
}

.MuiDataGrid-row {
  min-height: 37px !important;
  max-height: 37px !important;
}

.MuiDataGrid-cell {
  min-height: 37px !important;
  max-height: 37px !important;
  line-height: 36px !important;
}

.MuiDataGrid-columnsContainer {
  min-height: 30px !important;
  max-height: 30px !important;
  line-height: 30px !important;
}

.MuiDataGrid-window {
  top: 30px !important;
  direction: rtl !important;
}

/* .MuiDataGrid-cell:last-child {
    display: none !important;
} */

.MuiDataGrid-columnHeader:nth-child(6) > .MuiDataGrid-columnSeparator {
  display: none !important;
}

.MuiDataGrid-columnHeaderWrapper > .MuiDataGrid-cell:last-child {
  /* border-bottom: 1px solid #576079 !important; */
  display: none;
}

.MuiDataGrid-root .MuiDataGrid-cell {
  border-bottom: 1px solid rgba(87, 96, 121, 0.6) !important;
}

.MuiTablePagination-root {
  color: #ffffff !important;
}

.MuiSelect-icon {
  color: #ffffff !important;
}

.MuiIconButton-root.Mui-disabled {
  color: #ffffff !important;
}

.MuiDataGrid-sortIcon {
  opacity: 1 !important;
  color: #ffffff !important;
}

.MuiIconButton-label {
  color: #ffffff !important;
}

.MuiDataGrid-root .MuiDataGrid-overlay {
  background-color: #151724 !important;
  color: #ffffff !important;
}

/* .MuiDataGrid-windowContainer {
    height: 275px !important;
} */

.accountTable .MuiDataGrid-windowContainer {
  height: 100% !important;
}

/* .MuiDataGrid-dataContainer{
    min-height: 0 !important;
    height: 100% !important;
} */

.MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: transparent !important;
}

.MuiDataGrid-row:hover {
  cursor: pointer;
  background-color: #25293d !important;
}

.MuiDataGrid-root .MuiDataGrid-row {
  padding-bottom: 0.33rem !important;
  margin-bottom: 0 !important;
}

.accountTable {
  width: 100%;
}

.accountTable .MuiDataGrid-root .MuiDataGrid-viewport {
  height: 100% !important;
  max-height: 100% !important;
  flex-direction: initial !important;
}

.accountTable .MuiDataGrid-renderingZone {
  height: 100% !important;
  max-height: 100% !important;
}

.MuiDataGrid-columnHeader:last-child > .MuiDataGrid-columnSeparator {
  display: none !important;
}
