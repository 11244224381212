.container {
  flex: 1 0 auto;
  display: initial;
}

.card {
  display: flex;
  flex-direction: column;
  position: relative;
}

.header {
  display: flex;
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #ffffff;
  border-bottom: 1px solid #25293d;
  padding: 0.75rem;
}

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.title {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #ffffff;
}

.chart {
  flex: 1;
  padding: 0 1.5rem 0 1rem;
}

.flex {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
