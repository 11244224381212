.loading__container {
  width: 100%;
  height: 100%;
  display: flex;
}

.loading__text {
  display: block;
  margin: auto;
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #ffffff;
  text-align: center;
}
