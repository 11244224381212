.sidebar {
  list-style-type: none;
  padding-inline-start: 0px;
}

.sidebar__item {
  height: 40px;
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  border: 5px transparent;
  display: flex;
  color: #ffffff;
}

.sidebar__item a {
  text-decoration: none;
  color: #000000;
  width: 100%;
  height: 100%;
  display: flex;
  border-left: 5px solid transparent;
}

.sidebar__item a:hover {
  color: rgba(87, 96, 121, 0.6);
}

.sidebar__icon {
  margin-right: 12px;
}

li .active {
  background: rgba(87, 96, 121, 0.6);
  border-left: 5px solid #0096dd;
}

.sidebar__nav__contentWraper {
  margin: auto 15px;
}

.sidebar__nav__title {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.33rem;
  color: #ffffff;
}

nav {
  margin-top: 2rem;
}
