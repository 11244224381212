@mixin titleRowBtnMixin() {
  border-radius: 4px;
  background: #0096dd;
  border: none;

  &:hover {
    background: #41baf4;
  }

  &:disabled {
    background: gray;
    color: black;
  }

  /* Auto Layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  /* System/Secondary */
  color: #fefefe;
}

.titleRow {
  display: flex;
  margin-bottom: 1rem;
  //justify-content: space-between;

  .title {
    font-family: Source Sans Pro, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: #ffffff;
    min-width: 140px;
    margin: auto 0.5rem auto 0;
  }

  .searchBox {
    margin-left: 10px;
    border: 1px solid #576079;
    background-color: transparent;
    border-radius: 4px;
    padding: 0 0.5rem;
    font-family: Source Sans Pro, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.5rem;

    .search {
      border: none;
      background-color: transparent;
      padding: 0;
      color: #f0f0f0;
      caret-color: rgba(240, 240, 240, 0.5);
    }

    .searchButton {
      border: none !important;
      background: transparent;
    }
  }
}

.titleRowButton {
  @include titleRowBtnMixin();
}

.filterRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  height: 30px;
}

.filters {
  display: flex;

  .accountsFilterContainer {
    margin-left: 20px;
  }

  .titleRowButtonRoot {
    @include titleRowBtnMixin();

    div {
      padding: 0.5rem 1rem;

      img {
        height: 1.25rem;
        margin: 0 0.25rem 0.125rem -5px;
      }

      span {
        vertical-align: middle;
        font-size: 0.9rem;
      }
    }
  }
}

.accountInfoImportListFilterMenuItem {
  padding-bottom: 0 !important;
  margin-bottom: calc(-0.5rem - 1px) !important;
  img {
    margin-left: -5px;
    height: 1.25rem;
  }
  button {
    color: #0078b1;
    background: #25293e;
    border: 0;
    font-size: 0.9rem;
  }
  button:hover {
    text-decoration: underline;
  }
}
