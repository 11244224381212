.container {
  width: 230px;
  height: 30px;

  display: flex;
  border: 1px solid #576079;
  border-radius: 4px;

  &.containerSmall {
    height: 25px;
  }

  .xBtn {
    width: 30px;
    height: 100%;
    background: #151724;
    color: #f0f0f0;
    border: 0;
    border-right: 1px solid #576079;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }

  .toggleRangePopperBtn {
    //width: 200px;
    position: relative;
    height: 100%;
    flex: 1;
    background: #151724;
    color: #f0f0f0;
    border: 0;
    text-align: left;
    border-radius: 4px;

    .arrowDownSvg {
      position: absolute;
      right: 10px;
      top: 12px;

      @at-root .containerSmall#{&} {
        top: 10px;
      }
    }
  }
}

.popperContentContainer {
  padding: 10px 15px;
}

.filterLabelColor {
  color: #f0f0f0;
}

.resetButton {
  background: none;
  border: none;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: #06a3ed;
  padding: 0;
}
