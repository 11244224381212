.container {
  margin-top: 1rem;
  min-height: 11rem;
}

.loadingMinHeight {
  min-height: 11rem;
}

.devices {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.25rem;
  color: #ffffff;
}

.text {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.1rem;
  line-height: 20px;
  color: #ffffff;
  margin-bottom: 0.5rem;
}
