.container {
  border: 1px solid #576079;
  border-radius: 8px;
  padding: 0.5rem;
  /* min-height: 11.5rem; */
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.fullScreenView {
  height: calc((100% - 2rem) / 3);
  margin-bottom: 1rem;
}

.tabsView {
  height: 35%;
  margin-bottom: 1rem;
}

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.title {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #ffffff;
  flex-grow: 0;
}

.chart {
  width: 100%;
  /* height: 10rem; */
  flex-grow: 1;
  height: calc(100% - 1.25rem);
}

.loading__container {
  width: 100%;
  height: 100%;
  display: flex;
}

.loading__text {
  display: block;
  margin: auto;
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #ffffff;
  text-align: center;
}
