html {
  font-size: 80%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  list-style-type: none;
}

html,
body {
  height: 100%;
}

.accountInfoRightSideTabs .tab-content {
  overflow: auto !important;
  margin-top: 0.25rem;
  margin-right: -12px;
}

.accountInfoLeftSideTable .MuiDataGrid-root .MuiDataGrid-row.Mui-selected {
  background-color: #25293d;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent !important;
}

.absVerticalCenterContent {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.accountInfoFilterMenuItem:hover {
  background-color: #576079 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(87, 96, 121, 0.6) !important;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(87, 96, 121, 0.6) !important;
}
