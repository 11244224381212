.bodyContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.noResultMsg {
  color: white;
  margin: auto;
  text-align: center;
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.125rem;
  color: #ffffff;
}

.chartsContainer {
  width: 100%;
  display: flex;
  flex-grow: 1;

  .chartsContainer__left {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .chartsContainer__left__top {
      height: 50%;
      width: 100%;
    }
    .chartsContainer__left__bottom {
      height: 50%;
      width: 100%;
    }
  }

  .chartsContainer__middle {
    width: 35%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .chartsContainer__middle__top {
      height: 50%;
      width: 100%;
    }
    .chartsContainer__middle__bottom {
      height: 50%;
      width: 100%;
    }
  }

  .chartsContainer__right {
    width: 35%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .chartsContainer__right__top {
      height: 50%;
      width: 100%;
    }
    .chartsContainer__right__bottom {
      height: 50%;
      width: 100%;
    }
  }
}
