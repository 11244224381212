.container {
  height: 100%;
  width: 100%;
}

.header {
  display: flex;
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #ffffff;
  border-bottom: 1px solid #334162;
  padding: 0.75rem;
  flex-grow: 0;
}

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.title {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #ffffff;
}

.chart {
  // border: 1px dashed white;
  // border-width: 0 1px 0 1px;
  margin: 0.5rem 0.75rem;
  flex-grow: 1;
}

.chartWrapper {
  padding: 0.5rem 0.75rem;
  flex-grow: 1;
}

.dates {
  flex-grow: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1rem;
  color: #ffffff;
}

.dashedDivider {
  border-top: 1px dashed white;
  flex-grow: 1;
  margin: 0.5rem 0.75rem;
}

.card {
  position: relative;
}
