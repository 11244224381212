.container {
  height: 100%;
  width: 100%;
}

.statistics {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  flex-grow: 0;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  border: 1px solid transparent;
  border-bottom: 1px solid #334162;
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0.5rem 1rem !important;
  padding-bottom: calc(0.5rem + 3px) !important;
  color: #ffffff;
}

.main {
  padding: 1rem;
  flex-grow: 1;
  height: calc(100% - 3.25rem);
}
