.container {
  width: 100%;
  height: 100%;
}

.logo {
  margin: auto 1rem auto 0;
  height: 100%;
  max-height: 70px;
}

.statisticValues {
  margin: auto 0;
}

.values {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  /* font-size: 1.75rem; */
  /* line-height: 1.5rem; */
  /* margin-top: 1rem; */
  font-size: 55%;
  line-height: 100%;
}

.numberCategory {
  /* font-size: 1.3rem; */
  font-size: 70%;
}

.main {
  color: #f0f0f0;
  display: flex;
  height: 100%;
}

.statistic {
  display: flex;
  height: 50%;
}

.numbers {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.4rem;
  display: flex;
}

.text {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: normal;
  /* font-size: 1rem; */
  font-size: 32%;
  line-height: 120%;
}

.sharingColor {
  color: #3380ff;
}

.shadowColor {
  color: #4bcad4;
}

.subtext {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8rem;
  line-height: 1rem;
  color: #ffffff;
}

.subtext > div {
  margin-bottom: 0.5rem;
}

.left {
  border-right: 0.5px solid #576079;
  padding-right: 2rem;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  font-size: 5vh;
}

.right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem 2rem;
}

.card {
  padding: 1rem;
}

.divider {
  border-bottom: 1px solid #f0f0f0;
  margin: auto 0.75rem;
  flex: 1 1 auto;
}

.flex {
  display: flex;
}

.info {
  margin-left: 0.5rem;
}

.chartText {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 130%;
  color: #e1e1e1;
}

.tooltip {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #151724;
}
