.MuiTooltip-tooltip {
  padding: 0.9rem;
  border-radius: 5px !important;
  background: #fefefe !important;
  font-family: Source Sans Pro, sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  color: #151724 !important;
}

.MuiTooltip-arrow {
  color: #fefefe !important;
}
