.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.6);
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
}

.customize {
  color: #0096dd;
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30%;
  height: fit-content;
  margin: auto 0;
}

.customizeIcon {
  margin: auto 0.25rem auto 0;
  height: 100%;
}

.textEnd {
  justify-self: flex-end;
}

.window {
  color: white;
  background: #151724;
  border-radius: 8px;
  border-top: 5px solid #06a3ed;
  margin: 10% auto;
  width: fit-content;
  min-width: 25%;

  .window_container {
    background: #151724;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .window_header {
    padding: 0.75rem 1.5rem;

    .window_title {
      display: flex;
      justify-content: space-between;
      font-size: 1.2rem;
      font-weight: 600;
      margin-bottom: 0.25rem;

      .cancelIcon {
        height: 0.6rem;
        cursor: pointer;
      }
    }

    .window_subtitle {
      color: #9aa0af;
      font-size: 0.75rem;
      font-weight: normal;
    }
  }

  .window_divider {
    border-bottom: 1px solid #576079;
  }

  .window_content {
    padding: 0.75rem 1.5rem 1.5rem 1.5rem;

    .window_content_icon {
      margin-bottom: auto;
      margin-right: 0.5rem;
    }

    .window_content_title {
      font-weight: 600;
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }

    .window_item {
      display: flex;
      justify-content: space-between;
      padding: 0.4rem 0;

      .window_item_left {
        height: fit-content;
        margin: auto 0;

        .window_item_label {
          margin-right: 1rem;
        }

        .window_item_category {
          margin-right: 1rem;
        }
      }

      .window_item_right {
        display: flex;

        .info {
          margin-right: 1rem;
        }

        input {
          color: white !important;
        }
      }
    }

    .window_item_divider {
      border-bottom: 1px solid #c2c2c2;
      margin: 0.4rem 0;
    }
  }

  .window_totalROI {
    div {
      margin-bottom: 0.5rem;
    }

    div:first-child {
      font-weight: bold;
      margin-top: 1rem;
    }
  }

  .window_buttonsRow {
    display: flex;
    justify-content: end;
    margin-top: 1.5rem;

    .window_cancelButton {
      color: #06a3ed;
      background-color: transparent;
      border: none;
      font-weight: 600;
      margin-right: 1rem;
    }

    .window_saveButton {
      background-color: white;
      border-radius: 4px;
      padding: 5px 12px;
      border: none;
      font-weight: 600;
    }
  }
}
