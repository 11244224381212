.App {
  text-align: center;
}

header {
  height: 4.75rem;
  width: 100%;
  display: flex;
  padding: 0 1.5625rem;
  justify-content: space-between;
}

.titleWrapper {
  display: flex;
}

.logout {
  background-color: transparent;
  border: none;
  color: #ffffff;
}

.main {
  margin-left: 17.5rem;
  height: 100vh;
  background: #334162;
}

.csfeyeTitle {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.75rem;
  line-height: 1.875rem;
  margin: auto 0.75rem;
  color: #ffffff;
}

.logo {
  width: 2.6875rem;
  height: 2.6875rem;
  margin: auto 0;
}

.loadingPage {
  display: grid;
  border-radius: 20px 0px 0px 0px;
  background: #151724;
  padding-bottom: 10%;
}

.trialAlertBox {
  display: flex;
  height: 65%;
  margin: auto 0;
  background: #d6e6ff;
  border-radius: 4px;
  padding: 0 1rem;
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;

  .trialAlertBox__infoImg {
    height: 60%;
    margin: auto 0;
    margin-right: 0.5rem;
  }

  .trialAlertBox__text {
    color: #154180;
    margin: auto 0;

    span {
      margin-right: 0.5rem;
    }

    span:first-child {
      font-weight: 600;
    }
  }

  button {
    background: none;
    border: none;
    padding: 0;
  }

  .trialAlertBox__moreBtn {
    color: #06a3ed;
    font-weight: 600;
    margin-right: 0.5rem;
  }
}
