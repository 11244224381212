.container {
  width: fit-content;
  height: 30px;

  display: flex;
  border-radius: 4px;
  margin-bottom: 0.5rem;

  &.containerSmall {
    height: 25px;
  }

  .xBtn {
    width: 30px;
    height: 100%;
    background: #25293e;
    color: #f0f0f0;

    border: 0;
    border-right: 1px solid #576079;
  }

  .toggleRangePopperBtn {
    position: relative;
    height: 100%;
    flex: 1;
    background: #0096dd;
    color: #f0f0f0;
    border: 0;
    text-align: left;
    border-radius: 4px;
    font-family: Source Sans Pro, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;

    .householdIcon {
      height: 60%;
      margin-right: 5px;
      vertical-align: sub;
    }

    .arrowDownSvg {
      margin-left: 15px;
      vertical-align: middle;
      margin-bottom: 3px;

      @at-root .containerSmall#{&} {
        top: 10px;
      }
    }
  }
}

.popperContentContainer {
  padding: 0px 15px;
}

.popperContentContainer__selectAll {
  padding: 0px 8px;
  border-bottom: 1px solid #576079;
  width: 100%;
}

.filterLabelColor {
  color: #f0f0f0;
  padding: 0 5px;
}
