.dataGrid {
  overflow-y: hidden !important;
  overflow-x: scroll !important;

  .MuiDataGrid-main {
    // overflow-y: auto !important;
  }

  .MuiDataGrid-root {
    border: none !important;
  }

  .MuiDataGrid-root .MuiDataGrid-columnsContainer {
    border: none !important;
    overflow: unset !important;
    padding: 0 !important;
  }

  .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
    overflow: initial !important;
    font-family: Source Sans Pro, sans-serif;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #ffffff;
  }

  .MuiDataGrid-root .MuiDataGrid-row {
    background-color: #25293d;
    border-radius: 8px !important;
    margin-bottom: 0.5rem !important;
  }

  .MuiDataGrid-root .MuiDataGrid-cell {
    font-family: Source Sans Pro, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    color: #ffffff;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  .MuiSelect-outlined.MuiSelect-outlined {
    font-family: Source Sans Pro, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.25rem;
    color: #ffffff;
  }

  .MuiSelect-select {
    padding: 0 32px 0 0 !important;
  }

  .MuiDataGrid-row {
    min-height: 37px !important;
    max-height: 37px !important;
  }

  .MuiDataGrid-cell {
    min-height: 37px !important;
    max-height: 37px !important;
    line-height: 36px !important;
  }

  .MuiDataGrid-columnsContainer {
    min-height: 30px !important;
    max-height: 30px !important;
    line-height: 30px !important;
  }

  .MuiDataGrid-window {
    top: 30px !important;
    overflow: hidden !important;
  }

  // .MuiDataGrid-cell:last-child {
  //   display: none !important;
  // }

  .MuiDataGrid-columnHeader:last-child > .MuiDataGrid-columnSeparator {
    display: none !important;
  }

  .MuiDataGrid-root .MuiDataGrid-cell {
    border-bottom: none !important;
  }

  .MuiTablePagination-root {
    color: #ffffff !important;
  }

  .MuiSelect-icon {
    color: #ffffff !important;
  }

  .MuiIconButton-root.Mui-disabled {
    color: #ffffff !important;
  }

  .MuiDataGrid-sortIcon:hover {
    opacity: 1 !important;
    color: #ffffff !important;
  }

  .MuiIconButton-label {
    color: #ffffff !important;
  }

  .MuiDataGrid-root .MuiDataGrid-overlay {
    background-color: #25293d !important;
    color: #ffffff !important;
  }

  .MuiDataGrid-root .MuiDataGrid-columnHeader,
  .MuiDataGrid-root .MuiDataGrid-cell {
    padding: 0 7.5px 0 0 !important;
  }

  .MuiDataGrid-columnHeader--sortable .MuiDataGrid-columnSeparator {
    display: none !important;
  }

  .MuiDataGrid-root .MuiDataGrid-row.Mui-selected {
    background-color: #151724 !important;
  }

  .MuiDataGrid-selectedRowCount {
    display: none;
  }

  .MuiTablePagination-actions {
    display: flex !important;
  }

  .MuiButtonBase-root {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .MuiDataGrid-selectedRowCount {
    color: white;
  }

  .MuiDataGrid-root .MuiDataGrid-columnHeader {
    border-bottom: none !important;
  }

  .MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
    padding: 0 0 0 0 !important;
  }

  .MuiDataGrid-root .MuiDataGrid-viewport {
    margin: 0 1 0 0 !important;
  }
}

.MuiMenu-list {
  padding: 0 !important;

  .MuiButtonBase-root {
    display: block !important;
    padding: 0.5rem 0.75rem !important;
  }
}

.MuiSelect-outlined.MuiSelect-outlined {
  -webkit-text-decoration-line: none !important;
  text-decoration-line: none !important;
}

*:focus,
*:focus-within {
  outline: none !important;
}

.MuiPaper-root {
  width: fit-content !important;
}

.flag-icon {
  margin-right: 0.5rem !important;
  height: "1.5rem" !important;
}
