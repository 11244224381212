.container {
  min-width: 70%;
  height: 100%;
}

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.tab {
  height: 100%;
}
