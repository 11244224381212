.tabsView {
  // .container {
  //   border: 1px solid #576079;
  //   border-radius: 8px;
  //   padding: 0.5rem;
  // }
}

.container,
.loadingMinHeight {
  min-height: 11rem;
  margin-bottom: 1rem;
}

.chartWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.title {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #ffffff;
  margin-bottom: 0.5rem;
}

.chart {
  width: 100%;
  flex: 1 1 auto;
}

.placeholderText {
  margin: 5rem auto;
  width: fit-content;
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 1.75rem;
  color: #ffffff;
}
