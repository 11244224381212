.bodyContainer {
  display: flex;
  height: 100%;
  min-height: 0;
  width: 100%;
  border: solid #25293d 2px;
  border-radius: 8px;
}

.leftSide {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.leftSide__top {
  width: 100%;
  /* height: 14%; */
  /* flex: 1 0 auto; */
  /* display: flex; */
  /* flex-direction: column; */
}

.leftSide__bottom {
  width: 100%;
  /* height: 86%; */
  flex: 1 1 auto;
  /* display: flex; */
  /* flex-direction: column; */
}

.rightSide {
  width: 50%;
  height: 100%;
  /* display: flex; */
  /* flex-direction: column; */
}

.statistics {
  display: flex;
  max-height: 18%;
  flex: 1 0 auto;
}

.tabsContainer {
  height: 100%;
  // margin: 0.5rem;

  .card {
    margin: 0;
    padding: 0.75rem 0.75rem 0.25rem 0.75rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    border-radius: 0;
  }

  .title {
    font-family: Source Sans Pro, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1.25rem;
    flex-grow: 0;
    margin-bottom: 0.5rem;
  }

  .titleSelectedAccountLabel {
    font-family: Source Sans Pro, sans-serif;
    font-style: normal;
    text-transform: capitalize;
    background: rgba(87, 96, 121, 0.6);
    border-radius: 2px;
    margin: 0 0.5rem 0.25rem 0;
    font-weight: 400;
    font-size: 1.05rem;
    padding: 0 0.75rem;
    display: inline-block;
    color: white;
  }

  .main {
    flex: 1;
    min-height: 0;
    //overflow: scroll;
    display: flex;
    flex-direction: column;

    .tabList {
      flex-grow: 0;
      margin: 0 calc(-0.75rem - 2px);
    }

    .tabContent {
      margin-right: 6px;
      .forceContentMinHeight {
        min-height: 150px;
      }
    }
  }
}

.tab {
  height: 100%;
  padding: 1rem 0;
}

.fullScreenButton {
  background: none;
  border: none;
  color: #0096dd;
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;

  .icon {
    margin-right: 0.5rem;
    height: 1rem;
    width: 1rem;
  }
}
