.nav-tabs {
  border-bottom: 1px solid #334162 !important;
}

.tab-content {
  /* padding: 0 1rem;
    margin-bottom: 1rem; */
  flex: 1 1 auto;
  position: relative;
  overflow-y: scroll;
}

.tab-content > .tab-pane {
  height: 100%;
  overflow-x: hidden;
}

.nav-item {
  padding: 0 1rem;
}

.nav-link {
  color: #ffffff !important;
  background-color: transparent !important;
  border-color: transparent !important;
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0.5rem !important;
}

.nav-link.active {
  border-bottom: 3px solid #f0f0f0 !important;
  font-weight: 600;
}
