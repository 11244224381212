.container {
  min-height: 11rem;
  background-color: transparent !important;
  margin: 0;
  height: 100%;
  padding: 0.5rem 0;
}

.main {
  height: 100%;
  position: relative;
  /*display: flex;
  flex-direction: column; */
}

.loadingMinHeight {
  min-height: 11rem;
}

.accountTable {
  width: 100%;
  height: 100%;
}

.totalRecords {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: white;
}

.revealButton {
  border-radius: 4px;
  background: #0096dd;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fefefe;
  line-height: 1.5rem;
  margin: auto 0.5rem;
  min-width: 3.5rem;
  max-width: 3.5rem;
  min-height: 1.6rem;
  max-height: 1.6rem;
}

.revealButtonNotAllowed {
  border-radius: 4px;
  background: #0096dd;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fefefe;
  line-height: 1.5rem;
  margin: auto 0.5rem;
  min-width: 3.5rem;
  max-width: 3.5rem;
  min-height: 1.6rem;
  max-height: 1.6rem;
  cursor: auto !important;
}

.revealButton:disabled {
  background: #97999b;
  cursor: auto;
  opacity: 0.9;
}
