.container {
  height: 100%;
  width: calc(50% - 0.5rem);
  flex: 1 0 auto;
}

.detailsWrapper {
  margin: auto 0;
  width: 35%;
}

.details {
  height: calc((100% - 1.25rem - 1px) * 0.2);
  display: flex;
  justify-content: space-between;
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: normal;
  /* font-size: 0.9rem; */
  font-size: 30%;
  /* line-height: 1.5rem; */
  color: #f0f0f0;
  /* display: flex; */
  margin-bottom: 0.3rem;
  padding-right: 1rem;
}

.card {
  padding: 1rem;
}

.wrapper {
  height: 100%;
  width: 100%;
  font-size: 5vh;
  display: flex;
  flex-direction: row;
}

.values {
  height: 50%;
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 50%;
  /* line-height: 1.5rem;
  margin-top: 1rem; */
}

.timeCategory {
  /* font-size: 1.3rem; */
  font-size: 80%;
}

.main {
  color: #f0f0f0;
  display: flex;
  height: calc((100% - 1.25rem - 1px) * 0.6);
  width: 50%;
  margin: auto 0;
}

.newClass {
  margin: auto 0;
}

.divider {
  border-right: 1px solid #576079;
  margin: 0.5rem 1rem 0.75rem 0;
}

.logo {
  margin: auto 1rem auto 0;
  height: 100%;
  max-height: 70px;
}

.numbers {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.4rem;
  display: flex;
}

.text {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: normal;
  /* font-size: 1rem; */
  font-size: 30%;
}
