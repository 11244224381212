.fullScreenView {
  .barsContainer {
    display: flex;
    padding: 1.25rem;
  }

  .mainBar {
    display: inline-flex;
    justify-content: space-between;
    padding: 0 5rem 0 0;
    margin: 0;
    border-right: 1px solid #576079;
    width: 40%;
  }

  .secondaryBar {
    display: inline-flex;
    justify-content: space-between;
    width: 60%;
    padding: 0 4rem 0 5rem;
    margin: 0;
  }

  .noMarginContainer {
    margin: 0;
    border: none;
    width: 100%;
    min-height: 83px;
  }
}

.tabsView {
  height: fit-content;
  position: relative;
  margin: 1rem 0;

  .container {
    height: 100%;
    width: 100%;
    border: 1px solid #576079;
    border-radius: 8px;
    padding: 1rem;
    margin: 0;
  }

  .barsContainer {
    display: flex;
    flex-direction: column;
  }

  .mainBar {
    display: inline-flex;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    // border-right: 1px solid #576079;
  }

  .divider {
    border-bottom: 0.5px solid #576079;
    width: 100%;
    margin: 1rem 0;
  }

  .secondaryBar {
    display: inline-flex;
    justify-content: space-between;
    padding: 0;
    margin: 0;
  }
}
