.loading__container {
  width: 100%;
  height: 100%;
  display: flex;
}

.loading__wrapper {
  display: flex;
  margin: auto;
}

.loading__text {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #ffffff;
  margin: auto 0.5rem;
}
