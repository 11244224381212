.bodyContainer {
  display: flex;
  height: 100%;
  width: 100%;
}

.leftSide {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.leftSide__top {
  width: 100%;
  height: 25%;
  display: flex;
  /* flex-direction: column; */
}

.leftSide__top__left {
  width: 25%;
  height: 100%;
  display: flex;
  /* flex-direction: column; */
}

.leftSide__top__right {
  width: 75%;
  height: 100%;
  display: flex;
  /* flex-direction: column; */
}

.leftSide__bottom {
  width: 100%;
  height: 75%;
  display: flex;
  /* flex-direction: column; */
}

.leftSide__bottom__left {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.leftSide__bottom__left__top {
  width: 100%;
  height: 50%;
  /* display: flex; */
  /* flex-direction: column; */
}

.leftSide__bottom__left__bottom {
  width: 100%;
  height: 50%;
  /* display: flex; */
  /* flex-direction: column; */
}

.leftSide__bottom__right {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.leftSide__bottom__right__top {
  width: 100%;
  height: 25%;
  display: flex;
  /* flex-direction: column; */
}

.leftSide__bottom__right__bottom {
  width: 100%;
  height: 75%;
  display: flex;
  /* flex-direction: column; */
}

.rightSide {
  width: 15%;
  height: 100%;
  /* display: flex; */
  /* flex-direction: column; */
}

.statistics {
  display: flex;
  max-height: 18%;
  flex: 1 0 auto;
}
