.prop {
  height: fit-content;
  display: flex;
  margin: auto 0;
}

.barIcon {
  margin-right: 0.75rem;
  margin-top: 0.25rem;
}

.secondaryIcon {
  margin-right: 0.25rem;
  margin-top: 0.1rem;
}

.value {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.75rem;
  line-height: 1.875rem;
  color: #ffffff;
}

.secondaryValue {
  font-size: 1.25rem !important;
  line-height: 1.25rem !important;
}

.title {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #ffffff;
}

.valueOverAvg {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 130%;
  color: #ffffff;
}

.some {
  display: flex;

  .valueOverAvg {
    align-self: center;
    font-size: 0.875rem;
    color: #9aa0af;
    padding-left: 0.5rem;
    border-left: 1px solid #576079;
    margin-left: 0.5rem;
  }
}
