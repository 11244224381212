.container {
  width: 300px;
  height: 30px;

  display: flex;
  border: 1px solid #576079;
  border-radius: 4px;

  &.containerSmall {
    height: 25px;
  }

  .xBtn {
    width: 30px;
    height: 100%;
    background: #25293e;
    color: #f0f0f0;
    border: 0;
    border-right: 1px solid #576079;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .toggleRangePopperBtn {
    //width: 200px;
    position: relative;
    display: flex;
    height: 100%;
    flex: 1;
    background: #25293e;
    color: #f0f0f0;
    padding: 1px 6px;
    border: 0;
    text-align: left;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      flex: 1;
      width: 1px;
      margin: auto 0;
    }

    .loadingWrapper {
      display: flex;
      flex: 0;
      margin: 0.25rem 0 0.25rem 0.5rem;
      color: white;
      background: rgba(87, 96, 121, 0.6);
      border-radius: 2px;
      padding: 0.25rem;
      height: calc(100% - 0.5rem);
      font-size: x-small;
    }

    .loadingText_uploading {
      color: #576079;
    }

    .loadingText_success {
      color: #2ed15e;
    }
  }
}

.popperContentContainer {
  padding: 10px 15px 0px 15px;
}

.filterLabelColor {
  color: #f0f0f0;
}
