.container {
  display: flex;
  justify-content: space-between;
}

.backButton {
  border: none;
  background: none;
  height: fit-content;
}

.searchBox {
  margin-bottom: 1rem;
  border: 1px solid #9aa0af;
  background-color: transparent;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
}

.search {
  border: none;
  background-color: transparent;
  padding: 0;
  color: #f0f0f0;
  caret-color: rgba(240, 240, 240, 0.5);
}

.searchButton {
  border: none !important;
  background: transparent;
}

.title {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 1.75rem;
  color: #ffffff;
}

*:focus {
  outline: none;
}

::placeholder {
  color: #9aa0af;
  opacity: 1;
}

.titleSelectedAccountLabel {
  text-transform: capitalize;
  background: rgba(87, 96, 121, 0.6);
  border-radius: 2px;
  margin: 0.25rem 0.25rem 0 0.25rem;
  font-weight: 400;
  font-size: 1.05rem;
  padding: 0 0.75rem;
  display: inline-block;
  color: white;
}
