.titleContainer {
  display: flex;
}

.title {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 1.75rem;
  color: #ffffff;
}

.dateRange {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.1rem;
  line-height: 1.25rem;
  color: #f0f0f0;
  padding-left: 0.75rem;
  margin: auto 0 auto 0.75rem;
  border-left: 1px solid #ffffff;
}
