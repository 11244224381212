.container {
  flex: 1 1 auto;
  display: initial;
}

.card {
  display: flex;
  flex-direction: column;
  position: relative;
}

.header {
  display: flex;
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #ffffff;
  border-bottom: 1px solid #25293d;
  padding-bottom: 0.75rem;
}

.main {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.title {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #ffffff;
}

.chart {
  width: 100%;
  height: 100%;
  flex: 1;
  box-sizing: border-box;

  div {
    border-radius: 8px !important;
    border: 1px solid #576079 !important;
  }
}

.legend {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0.25rem 0 0.25rem;
}

.legentItem {
  display: flex;
}

.legendBullet {
  margin-right: 0.5rem;
}

.legendTitle {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #ffffff;
}

.flex {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
