$slidermark-color: white;
$slidermark-query-color: wheat;

@mixin sliderMarkLabel($index) {
  color: $slidermark-color; // Probably should be filterLabelColor
  &[data-index="#{$index}"] {
    color: $slidermark-query-color;
  }
}

.sliderMarksContainer {
  .sliderMarkLabel1 {
    @include sliderMarkLabel(0);
  }
  .sliderMarkLabel2 {
    @include sliderMarkLabel(1);
  }
  .sliderMarkLabel3 {
    @include sliderMarkLabel(2);
  }
  .sliderMarkLabel4 {
    @include sliderMarkLabel(3);
  }
  .sliderMarkLabel5 {
    @include sliderMarkLabel(4);
  }
  .sliderMarkLabel6 {
    @include sliderMarkLabel(4);
  }
  .sliderMarkLabel7 {
    @include sliderMarkLabel(4);
  }
}
