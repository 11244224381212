.header {
  display: flex;
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #ffffff;
  border-bottom: 1px solid #334162;
  padding: 0.75rem;
  flex-grow: 0;
}

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.title {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #ffffff;
}

.chartwrraper {
  flex-grow: 1;
  overflow-y: scroll;
}

.chart {
  padding: 0.5rem 0.75rem;
  height: 100%;
  width: 100%;
}

.card {
  position: relative;
}

.container {
  height: 100%;
}
