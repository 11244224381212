.titleRow {
  display: flex;
  margin: 0.5rem;
  max-height: 1.71875rem;
}

.title {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 1.75rem;
  padding: 0 1rem;
  color: #ffffff;
}

.bodyContainer {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.bodyContainer > *:not(:last-child) {
  flex: 1;
}

.placeholderText {
  margin: 5rem auto;
  width: fit-content;
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 1.75rem;
  color: #ffffff;
}

.chartsContainer {
  display: flex;
  position: relative;
  overflow: hidden;
  flex-grow: 2;
}

.dateRange {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.1rem;
  line-height: 1.25rem;
  color: #f0f0f0;
  padding-left: 0.75rem;
  margin: auto 0;
  border-left: 1px solid #f0f0f0;
}
